import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";

export default styled(Drawer)(({ theme, ownerState }) => {
  const { palette, boxShadows, transitions, breakpoints, functions } = theme;
  const { transparentSidenav, whiteSidenav, miniSidenav, darkMode } = ownerState;

  const sidebarWidth = 254;
  const { transparent, gradients, white, background } = palette;
  const { xxl } = boxShadows;
  const { pxToRem, linearGradient } = functions;

  let backgroundValue = darkMode
    ? "blue"
    : linearGradient(gradients.dark.main, gradients.dark.state);

  if (transparentSidenav) {
    backgroundValue = transparent.main;
  } else if (whiteSidenav) {
    backgroundValue = white.main;
  }

  // styles for the sidenav when miniSidenav={false}
  const drawerOpenStyles = () => ({
    background: "white",
    borderRadius: 0,
    margin: 0,
    height: "86.8%",
    transform: "translateX(0)",
    transition: transitions.create("transform", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.shorter,
    }),

    [breakpoints.up("xl")]: {
      // boxShadow: transparentSidenav ? "none" : xxl,
      boxShadow: transparentSidenav ? "none" : '0.3rem -1.95rem 1.8875rem 0rem rgba(0, 0, 0, 0.05)',
      marginBottom: transparentSidenav ? 0 : "inherit",
      left: "0",
      width: sidebarWidth,
      transform: "translateX(0)",
      transition: transitions.create(["width", "background-color"], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.enteringScreen,
      }),
    },
  });

  // styles for the sidenav when miniSidenav={true}
  const drawerCloseStyles = () => ({
    // background: backgroundValue,
    transform: `translateX(${pxToRem(-320)})`,
    // transition: transitions.create("transform", {
    //   easing: transitions.easing.sharp,
    //   duration: transitions.duration.shorter,
    // }),

    [breakpoints.up("xl")]: {
      // boxShadow: transparentSidenav ? "none" : xxl,
      boxShadow: transparentSidenav ? "none" : '0.3rem -1.95rem 1.8875rem 0rem rgba(0, 0, 0, 0.05)',
      marginBottom: transparentSidenav ? 0 : "inherit",
      left: "0",
      width: pxToRem(96),
      overflowX: "hidden",
      transform: "translateX(0)",
      transition: transitions.create(["width", "background-color"], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.shorter,
      }),
    },
  });

  return {
    "& .MuiDrawer-paper": {
      // boxShadow: xxl,
      boxShadow: '0.3rem -1.95rem 1.8875rem 0rem rgba(0, 0, 0, 0.05)',
      marginTop: "125px !important",
      position: "absolute !important",
      border: "none",
      // ...(drawerCloseStyles()),
      ...(miniSidenav ? drawerCloseStyles() : drawerOpenStyles()),
    },
  };
});
