import React from 'react'
import Box from '@mui/material/Box'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import SendOutlined from '@mui/icons-material/SendOutlined'
import { mibananaColor } from 'assets/new-images/colors'
import ReactQuill from "react-quill";
import { reactQuillStyles2 } from 'assets/react-quill-settings/react-quill-settings';
import { modules } from 'assets/react-quill-settings/react-quill-settings'
import { formats } from 'assets/react-quill-settings/react-quill-settings'
import usePrivateChat from './usePrivateChat'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Close } from '@mui/icons-material'
import FullScreenLoader from 'components/Loader/FullScreenLoader'
import { BeatLoader } from 'react-spinners'
import UserOnlineIcon from '../userOnlineicon'
import "../../miBananaTeamMembers.css";
import { formatMessageDate } from 'redux/global/table-date'

const PrivateChat = ({
    boxStyles,
    userId,
    username,
    user_avatar,
    closeChat,
    item,
    reduxState,
    reduxActions,

}) => {
    const { name, avatar, roles, _id } = item
    const classes = reactQuillStyles2()
    const { message, setMessage, chats, loading, sendMessage, privateChatRef } = usePrivateChat(userId, _id, name, username, avatar, user_avatar, reduxState, reduxActions, item)

    function avatarImage(item) {

        let profileImage = ''
        if (userId === item.sender) {
            profileImage = user_avatar
        } else if (_id === item.sender) {
            profileImage = avatar
        }
        return profileImage
    }
    function getImageAlt(item) {
        let alt = ''
        if (userId === item.sender) {
            alt = item.sender_name
        } else {
            alt = item.receivar_name
        }
        return alt
    }
    function userName(item) {
        let name = ''
        if (userId === item.sender) {
            name = username
        } else if (item.sender === _id) {
            name = item.sender_name
        }
        return name
    }
    function msgPosition(item) {
        let align = ''
        let bgColor = ''
        if (userId === item.sender) {
            align = 'flex-end'
            bgColor = '#bbf8b9'
        } else {
            align = 'flex-start'
            bgColor = '#fff'
        }
        return {
            alignSelf: align,
            backgroundColor: bgColor,

        }
    }
    const scrollStyle = {
        "&::-webkit-scrollbar": {
            width: '10px',
            height: '16px'
        },
        "&::-webkit-scrollbar-track": {
            background: '#f1f1f1',
        },
        "&::-webkit-scrollbar-thumb": {
            background: '#888',
            borderRadius: '10px',
        },
        "&::-webkit-scrollbar-thumb:hover": {
            background: '#555',
        }
    }
    const onlineWidth = (member) => {
        const currentUser = reduxState.onlineUser?.some(item => item.id === member._id)
        if (currentUser) {
            return { gap: '1rem' }
        }
        else {
            return null
        }
    }

    function copyMessageWithHtml(message) {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = message;
        document.body.appendChild(tempElement);
        const range = document.createRange();
        range.selectNodeContents(tempElement);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand('copy');
        document.body.removeChild(tempElement);
        selection.removeAllRanges();
        alert("Message copied to clipboard!");
    }

    const chatBoxStyle = {
        marginLeft: '10px', width: '100%',
        height: '100%', display: 'flex',
        padding: '0.5rem', paddingBottom: '14px',
        overflowY: "scroll", ...scrollStyle,
        marginBottom: '9px'
    }
    const chatBoxStyle2 = {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        gap: '1.5rem',
        paddingBottom: "12px"
    }

    const mainBox = { position: 'relative', width: '75%', marginLeft: "unset !important", boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px", borderRadius: "10px", ...boxStyles, display: "flex", flexDirection: "column", padding: "1.5rem", justifyContent: "space-between", transition: "width 0.5s ease-in-out", backgroundColor: mibananaColor.headerColor, }

    return (
        <Box sx={mainBox}>

            {/* <span style={{ position: 'absolute', right: 12 }}><IconButton><Close fontSize='medium' /></IconButton></span> */}
            <Box sx={{ width: '100%', display: 'flex', backgroundColor: mibananaColor.headerColor, }}>
                <ListItem divider focusRipple={true} disableTouchRipple={true} disableRipple={true} >
                    <ListItemButton disableRipple sx={{ "&:hover": { backgroundColor: "transparent !important" }, ...(onlineWidth(item)) }}>
                        {/* <ListItemIcon>
                            <Avatar src={avatar} alt={name} />
                        </ListItemIcon> */}
                        <UserOnlineIcon member={item} data={reduxState.onlineUser} />
                        <IconButton onClick={closeChat} className='chat-close-icon'><Close /></IconButton>
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    {name}
                                    <Typography
                                        variant="body2"
                                        component="div"
                                        color="textSecondary"
                                    >
                                        role : <i>{roles?.length ? roles[0] : ''}</i>
                                    </Typography>
                                </React.Fragment>
                            }
                        />
                    </ListItemButton>
                </ListItem>
            </Box>
            {loading ? (
                <FullScreenLoader>
                    <BeatLoader size={25} color={'#fff'} />
                </FullScreenLoader>
            ) : (<Box className={'chatBoxStyle'} >
                <Box ref={privateChatRef} className={'chatBoxStyle2'}>
                    {chats?.length > 0 ? chats?.map(item => {
                        return (
                            <ListItem disablePadding sx={{ backgroundColor: '#fff', width: '60%', ...(msgPosition(item)) }}>
                                <ListItemButton disableRipple sx={{ "&:hover": { backgroundColor: "transparent !important" }, position: 'relative' }}>
                                    <ListItemIcon>
                                        <Avatar src={avatarImage(item)} alt={item.sender_name} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <React.Fragment>
                                                {userName(item)}
                                                <Typography
                                                    variant="body2"
                                                    component="div"
                                                    color="textSecondary"
                                                    fontFamily={'"Poppins", sans-serif'}
                                                    dangerouslySetInnerHTML={{ __html: item.message }}
                                                    sx={{
                                                        wordWrap: 'break-word',
                                                        overflowWrap: 'break-word',
                                                        whiteSpace: 'pre-wrap',
                                                    }}
                                                >

                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    component="span"
                                                    fontFamily={'"Poppins", sans-serif'}
                                                    sx={{
                                                        position: 'absolute',
                                                        color: '#00000070',
                                                        fontSize: '.8rem',
                                                        bottom: '5px',
                                                        right: '15px',
                                                    }}
                                                >
                                                    {formatMessageDate(item.date)}
                                                </Typography>
                                                <IconButton
                                                    onClick={() => copyMessageWithHtml(item.message)}
                                                    sx={{ position: 'absolute', right: 0, top: 5 }}>
                                                    <ContentCopyIcon fontSize="small" />
                                                </IconButton>
                                            </React.Fragment>
                                        }
                                    />
                                </ListItemButton>
                            </ListItem>
                        )
                    }) : <Typography
                        variant="body2"
                        component="div"
                        color="title"
                        fontFamily={'"Poppins", sans-serif'}
                        textAlign={'center'}
                        sx={{
                            marginTop: '10px',
                            fontSize: '1.2rem',
                            fontWeight: '400'
                        }}
                    >
                        No Messages Found
                    </Typography>}
                </Box>
            </Box>
            )}


            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: mibananaColor.headerColor }}>
                <ReactQuill
                    theme="snow"
                    value={message}
                    onChange={(value) => setMessage(value)}
                    modules={modules}
                    formats={formats}
                    className={classes.quill}
                    style={{ width: '94%' }}
                />
                <IconButton onClick={sendMessage}>
                    <SendOutlined
                        fontSize="large"
                        sx={{
                            fontSize: "2.67rem !important",
                            scale: 1.5,
                            right: 20,
                            top: 54,
                            fill: 'rgba(0,0,0,0.7)',
                            cursor: "pointer",
                        }}
                    />
                </IconButton>
            </Box>
        </Box>
    )
}

export default PrivateChat
