import * as React from 'react';
import PropTypes from 'prop-types';
import { styled, css } from '@mui/system';
import { Modal as BaseModal, IconButton } from '@mui/material';
import Fade from '@mui/material/Fade';
import { fontsFamily } from 'assets/font-family';
import checkIcon from 'assets/images/check.png';
import CloseIcon from '@mui/icons-material/Close';


export default function TransitionsModal({ message, openModal, setOpenModal }) {
  const handleClose = () => setOpenModal(false);
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: StyledBackdrop }}
      >
        <Fade in={openModal}
          style={{ textAlign: "center", padding: "3rem" }}>
          <ModalContent sx={style}>
            <div style={{ position: "relative" }}>
              <IconButton onClick={() => setOpenModal(false)} sx={
                {
                  position: "absolute",
                  top: "-46px",
                  right: "-48px",
                  cursor: "pointer",
                  fontSize: "20px"
                }}
              >
                <CloseIcon fontSize='medium' />
              </IconButton>
              {/* <span onClick={() => setOpenModal(false)} style={{ position: "absolute", top: "-45px", right: "-30px", cursor: "pointer", fontSize: "20px" }}>x</span> */}
              <img src={checkIcon} style={{ marginBottom: "1rem" }} />
              <h2 id="transition-modal-title" style={{ color: '#333', fontFamily: fontsFamily.poppins, lineHeight: '2.7rem !important' }} className="modal-title">
                {message}
              </h2>
            </div>
          </ModalContent>
        </Fade>
      </Modal>
    </div>
  );
}

const Backdrop = React.forwardRef((props, ref) => {
  const { open, ...other } = props;
  return (
    <Fade in={open}>
      <div ref={ref} {...other} />
    </Fade>
  );
});

Backdrop.propTypes = {
  open: PropTypes.bool,
};


const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
};

const ModalContent = styled('div')(
  ({ theme, statuss }) => css`
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#F6F6E8'};                              
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0 4px 12px
      ${theme.palette.mode === 'dark' ? 'rgb(0 0 0 / 0.5)' : 'rgb(0 0 0 / 0.2)'};
    padding: 24px;
    color: ${theme.palette.mode === 'dark' ? grey[50] : grey[900]};

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${theme.palette.mode === 'dark' ? grey[400] : grey[800]};
      margin-bottom: 4px;
    }
  `,
);