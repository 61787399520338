import { useEffect, useRef } from 'react'
import { useSocket } from 'sockets';

export const useUpdateProjectNotifications = (reduxActions, reduxState, project_notifications) => {
    const socketIO = useSocket();


    const updatedNotifications = (unique_key) => {
        const id = reduxState.userDetails?.id
        socketIO.emit('update-current-notification', unique_key, id)
        socketIO.on('send-update-notification-status', (Ok) => {
            if (Ok) {
                const project_notif = project_notifications?.find(item => item.unique_key === unique_key);
                if (project_notif) {
                    let arr = project_notifications.map(item =>
                        item.unique_key === unique_key ? { ...item, view: false } : item
                    );
                    reduxActions.handleProject_notifications(arr);
                } 
            }
        })  

    }
    
    const deleteNotification = (id) => {
        const user_id = reduxState.userDetails?.id
        socketIO.emit('delete-current-notification', id, user_id)
        socketIO.on('confirmation-delete-notification', (done) => {
            if (done) {
                const deleteNotification = project_notifications?.filter(item => item.unique_key !== id)
                reduxActions.handleProject_notifications(deleteNotification)
            }
        })
    }

    useEffect(() => {
    }, [reduxState.project_notifications])
    return {
        updatedNotifications,
        deleteNotification,
    }
}