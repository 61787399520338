import PageLayout from 'examples/LayoutContainers/PageLayout'
import React from 'react'

const MiLayoutCover = ({ children }) => {
    return (
        <PageLayout >
            {children}
        </PageLayout>
    )
}

export default MiLayoutCover