import { TRIGER_NOTIFICATIONS } from "redux/actions/actions"
import { EDIT_PROJECT } from "redux/actions/actions"
import { PRIVATE_CHAT_MESSAGE } from "redux/actions/actions"
import { OPEN_RESET_PASSWORD } from "redux/actions/actions"
import { CURRENT_PROJECT_ID } from "redux/actions/actions"
import { STATUS_NOTIFICATIONS } from "redux/actions/actions"
import { PROJECT_NOTIFICATIONS } from "redux/actions/actions"
import { USER_DETAILS, SHOW_MODAL, PROJECT_CATEGORY, CUSTOMER_BRAND, NEW_BRAND, STATUS, IS_ALERT, CUSTOMER_PROJECTS, USER_AVATAR_URL, PROJECT_ID, IS_EDIT_BRAND, OPEN_BRAND_MODAL, RIGHTSIDEDRAWER, CURRENT_INDEX, RE_RENDER_CHAT, NON_ACTIVE_CUSTOMER, TOGGLE_CHATS, PROJECT_CALL, ONLINE_USER, UNREAD_CHAT_MESSAGE, GROUP_MESSAGE } from "redux/actions/actions"

const initialState = {
    userDetails: {},
    isModalShow: false,
    statuses: [
        "All",
        "Archived",
        "Cancelled",
        "Project manager",
        "Completed",
        "Ongoing",
        "For Review",
        "Draft",
        "Heads Up!",
    ],
    category: [
        "Graphic Design",
        "Mobile App Development",
        "Copywriting",
        "Illustration",
        "Video Editing",
        "Motion Graphic",
        "Web Development",
        "Voice Over",
        "Social Media Management Lite",
    ],
    brand: [
        "Nike",
        "Puma",
        "Ides",
        "Chen one"
    ],
    project_cat: "",
    project_list: [],
    user_avatar: "",
    project_ID: 0,
    isStatus: "",
    isAlert: false,
    new_brand: false,
    customerBrand: [],
    iseditBrand: false,
    openBrandModel: false,
    rightSideDrawer: { right: false },
    currentIndex: null,
    re_render_chat: false,
    trigerNotifcations: false,
    non_active_customer_data: [],
    toogle_chats: false,
    project_notifications: [],
    status_notifications: [],
    edit_project: false,
    currentProjectId: "",
    project_call: false,
    onlineUser: [],
    private_chat_message: [],
    unread_chat_message: [],
    open_reset_password : false, 
    group_message: [],

}
const UserReducers = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case USER_DETAILS:
            return {
                ...state,
                userDetails: payload
            }
        case SHOW_MODAL: {
            return {
                ...state,
                isModalShow: payload
            }
        }
        case PROJECT_CATEGORY: {
            return {
                ...state,
                project_cat: payload
            }
        }
        case CUSTOMER_PROJECTS: {
            return {
                ...state,
                project_list: payload
            }
        }
        case USER_AVATAR_URL: {
            return {
                ...state,
                user_avatar: payload
            }
        }
        case PROJECT_ID: {
            return {
                ...state,
                project_ID: payload
            }
        }
        case IS_ALERT: {
            return {
                ...state,
                isAlert: payload
            }
        }
        case STATUS: {
            return {
                ...state,
                isStatus: payload
            }
        }
        case NEW_BRAND: {
            return {
                ...state,
                new_brand: payload
            }
        }
        case CUSTOMER_BRAND: {
            return {
                ...state,
                customerBrand: payload
            }
        }
        case IS_EDIT_BRAND: {
            return {
                ...state,
                iseditBrand: payload
            }
        }
        case OPEN_BRAND_MODAL: {
            return {
                ...state,
                openBrandModel: payload
            }
        }
        case RIGHTSIDEDRAWER: {
            return {
                ...state,
                rightSideDrawer: payload
            }
        }
        case CURRENT_INDEX: {
            return {
                ...state,
                currentIndex: payload
            }
        }
        case RE_RENDER_CHAT: {
            return {
                ...state,
                re_render_chat: payload
            }
        }
        case TRIGER_NOTIFICATIONS: {
            return {
                ...state,
                trigerNotifcations: payload
            }
        }
        case NON_ACTIVE_CUSTOMER: {
            return {
                ...state,
                non_active_customer_data: payload
            }
        }
        case TOGGLE_CHATS: {
            return {
                ...state,
                toogle_chats: payload
            }
        }
        case PROJECT_NOTIFICATIONS: {
            if (Array.isArray(payload)) {
                // API call returns array
                return {
                    ...state,
                    project_notifications: [...payload]
                }

            } else {
                // Socket returns single object 
                return {
                    ...state,
                    project_notifications: [payload, ...state.project_notifications]
                }
            }
        }
        case STATUS_NOTIFICATIONS: {
            return {
                ...state,
                status_notifications: payload
            }
        }
        case EDIT_PROJECT: {
            return {
                ...state,
                edit_project: payload
            }
        }
        case CURRENT_PROJECT_ID: {
            return {
                ...state,
                currentProjectId: payload
            }
        }
        case PROJECT_CALL: {
            return {
                ...state,
                project_call: payload
            }
        }
        case ONLINE_USER: {
            return {
                ...state,
                onlineUser: payload
            }
        }
        case PRIVATE_CHAT_MESSAGE: {
            if (Array.isArray(payload)) {
                return {
                    ...state,
                    private_chat_message: [...payload]
                }

            } else {
                // Socket returns single object 
                return {
                    ...state,
                    private_chat_message: [...state.private_chat_message, payload]
                }
            }
        }
        case GROUP_MESSAGE: {
            if (Array.isArray(payload)) {
                return {
                    ...state,
                    group_message: payload.map(message => ({
                        ...message,
                        date: message.date instanceof Date ? message.date.toISOString() : message.date
                    }))
                }

            } else {
                // Socket returns single object 
                return {
                    ...state,
                    group_message: [...state.group_message, {
                        ...payload,
                        date: payload.date instanceof Date ? payload.date.toISOString() : payload.date
                    }]
                }
            }
        }
        case UNREAD_CHAT_MESSAGE: {
            if (Array.isArray(payload)) {
                return {
                    ...state,
                    unread_chat_message: payload.map(message => ({
                        ...message,
                        date: message.date instanceof Date ? message.date.toISOString() : message.date
                    }))
                }

            } else {
                return {
                    ...state,
                    unread_chat_message: [...state.unread_chat_message, {
                        ...payload,
                        date: payload.date instanceof Date ? payload.date.toISOString() : payload.date
                    }]
                }
            }
        } 
        case OPEN_RESET_PASSWORD: {
            return {
                ...state,
                 open_reset_password: payload
            }
        }
        default:
            return state
    }
}
export default UserReducers
